<template>
  <div class="container">
    <form class="row mt-4" @submit.prevent="pay = !pay">
      <div class="col-md-6">
        <div class="row">
          <h1 class="mt-3 main-color fw-bold">פרטי משלוח</h1>
          <h3 class="mt-3">פרטים אישיים</h3>
          <div class="col-md-6">
            <aInput class="mt-4" v-model="user.firstName" placeholder="*שם פרטי" type="text" />
          </div>
          <div class="col-md-6">
            <aInput class="mt-4" v-model="user.lastName" placeholder="*שם משפחה" type="text" />
          </div>
          <div class="col-md-6">
            <aInput class="mt-4" v-model="user.tel" placeholder="*טלפון" type="tel" pattern="[0-9]+" minlength="9" maxlength="11" />
          </div>
          <div class="col-md-6">
            <aInput class="mt-4" v-model="user.mail" placeholder="*אימייל" type="email" />
          </div>
          <div class="col-md-6">
            <aInput class="mt-4" v-model="user.city" placeholder="*עיר" type="text" />
          </div>
          <div class="col-md-6">
            <aInput class="mt-4" v-model="user.street" placeholder="*רחוב" type="text" />
          </div>
          <div class="col-md-4">
            <aInput class="mt-4" v-model="user.homeNum" placeholder="*מספר" type="text" />
          </div>
          <div class="col-md-4">
            <aInput class="mt-4" v-model="user.floor" placeholder="קומה" type="text" :required="false" />
          </div>
          <div class="col-md-4">
            <aInput class="mt-4" v-model="user.apartment" placeholder="דירה" type="text" :required="false" />
          </div>
          <h3 class="mt-3">הערות</h3>
          <div class="col-md-12">
            <textarea placeholder="כתוב כאן" v-model="user.comment" class="w-100 mt-3 h3 p-2" name="" id="" cols="30" rows="7"></textarea>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <h1 class="mt-3 main-color fw-bold">משלוח וסיכום הזמנה</h1>
        <div class="row f-center-between">
          <h3 class="col-3 mt-3">מוצרים</h3>
          <h3 class="col-3 mt-3 fw-bold">₪{{ $store.getters.payable }}</h3>
          <div class="col-12">
            <div class="w-100 border-bottom"></div>
          </div>
        </div>
        <div class="row f-center-between">
          <h3 class="col-3 mt-3">משלוח</h3>
          <!-- <div class="col-md-4 col-7 mt-3">
            <template v-if="$store.getters.messengerPrice">
              <myCheckbox class="h6" :check="ifMessenger" :send="true" :lable="`משלוח עד הבית ₪${$store.getters.messengerPrice}`" @toggle="$store.commit('toggleMessenger', $event)" />
              <myCheckbox class="h6" :check="!ifMessenger" :send="false" lable="איסוף עצמי מיצהר" @toggle="$store.commit('toggleMessenger', $event)" />
            </template>
            <template v-else>
              <h6>משלוח חינם:)</h6>
            </template>
          </div> -->
          <div class="col-12">
            <div class="w-100 border-bottom"></div>
          </div>
        </div>
        <div class="row f-center-between">
          <h3 class="col-4 mt-3">סה״כ לתשלום</h3>
          <h3 class="col-3 mt-3 fw-bold">₪{{ $store.getters.allPayable }}</h3>
        </div>
        <div class="row">
          <div class="col">
            <div class="wrap-input w-100 mt-3 ">
              <input class="m-0" type="checkbox" required />
              <label class="mb-0 me-2 text-right" name="checkbox" for="checkbox">קראתי ואני מסכים/ה לתנאי השימוש ומדיניות הפרטיות שבתחתית האתר</label>
            </div>
          </div>
        </div>
        <div class="row mt-5">
          <div class="col f-center">
            <button class="g-butt p-2 ps-4 pe-4 h3 m-0 fw-light" type="submit">מעבר לתשלום מאובטח</button>
          </div>
        </div>
      </div>
    </form>
    <modalPay v-if="pay" :user="user" @close-modal="pay = false" />
  </div>
</template>

<script>
// @ is an alias to /src
import aInput from '@/components/aInput.vue'
// import myCheckbox from '@/components/myCheckbox.vue'
import modalPay from '@/components/modalPay.vue'

export default {
  name: 'Home',
  components: {
    aInput,
    // myCheckbox,
    modalPay
  },
  data() {
    return {
      pay: false
      // user: {
      //   firstName: "",
      //   lastName: "",
      //   tel: "",
      //   mail: "",
      //   city: "",
      //   street: "",
      //   homeNum: "",
      //   floor: "",
      //   apartment: "",
      //   comment: ""
      // }
    }
  },
  mounted() {
    this.addProductFromUrl()
  },
  methods: {
    addProductFromUrl() {
      if(!this.$route.query.price || !this.$route.query.name) return
      this.$store.commit('addProductFromUrl', {
        price: this.$route.query.price,
        name: this.$route.query.name.split('-').join(' ')
      })
    }
  },
  computed: {
    products() {
      return this.$store.state.products
    },
    ifMessenger() {
      return this.$store.state.ifMessenger
    },
    user() {
      return this.$store.state.user
    }
  }
}
</script>

<style scoped>
.wrap-input {
  display: flex;
  align-items: baseline;
}

textarea {
  border: solid 2px var(--main-text-color);
}
</style>
