<template>
  <modal backgroundColor="#a6a6a600" @custom-event="$emit('close-modal')">
    <div class="w-100 h-100 box p-md-5 p-4 pt-5 pb-5 ">
      <h2>תשלום מאובטח</h2>
      <div class="w-100 f-center position-relative min-h-iframe">
        <!-- <h2 v-if="!url">המתן...</h2> -->
        <loader v-if="!url" />
        <iframe v-else class="w-100" :src="url" @load="loadIframe"></iframe>
      </div>
    </div>
  </modal>
</template>

<script>
  // @ is an alias to /src
  import modal from '@/components/modal.vue'
  import loader from '@/components/loader.vue'
  //  import aInput from '@/components/aInput.vue'
  // import gtag from "../helpers/gtag.js"

  export default {
    name: "modalPay",
    components: {
      modal,
      loader
      // aInput
    },
    props: {
      user: Object,
    },
    data() {
      return {
        url: "",
        loadIframeTimes: 0
      };
    },
    mounted() {
      this.getUrl();
    },
    methods: {
      async getUrl() {
        let body = {
          Items: this.arrOfItemsInCart,
          RedirectURL: "https://wine-store-s-production.up.railway.app/wine-store/get-auth-pay",
          ExemptVAT: false,
          MaxPayments: 1,
          CustomerFirstName: this.user.firstName,
          CustomerLastName: this.user.lastName,
          PhoneNumber: this.user.tel,
          EmailAddress: this.user.mail,
          City: this.user.city,
          Custom1: `street ${this.user.street}`,
          Custom2: `homeNum ${this.user.homeNum}`,
          Custom3: `floor ${this.user.floor}`,
          Custom4: `apartment ${this.user.apartment}`,
          Custom5: `comment ${this.user.comment}`
        };
        const json = await this.$store.dispatch("getUrl", body);
        this.url = json;
      },
      loadIframe() {
        this.loadIframeTimes++
        if(this.loadIframeTimes > 1) localStorage.removeItem("inCart")
      }
    },
     computed: {
        arrOfItemsInCart() {
          let arr = [];
          for (let i in this.itemsInCart) {
            arr.push({
              CatalogNumber: this.itemsInCart[i].id,
              Quantity: this.itemsInCart[i].amount,
              UnitPrice: this.itemsInCart[i].price,
              Description: this.itemsInCart[i].name
            })
          }
          if(this.$store.state.ifMessenger && this.$store.getters.messengerPrice) arr.push({
            CatalogNumber: 111,
              Quantity: 1,
              UnitPrice: this.$store.getters.messengerPrice,
              Description: 'משלוח'
          })
          return arr;
        },
        itemsInCart() {
          return this.$store.getters.inCart;
        }
      }
  };
</script>
<style scoped>
  h2 {
    /* text-decoration: underline; */
    color: var(--main-color);
    font-weight: 300;
    font-size: 27px;
  }

  .wrap {
    width: 50%;
  }

  iframe {
    height: 730px;
  }

  .box {
    border-radius: 20px;
    background-color: #fff;
    box-shadow: 0px 38px 99px #00000029;
    color: #000;
    overflow: hidden;
  }

  .min-h-iframe {
    min-height: 40vh;
  }

  @media (max-width: 767.98px) {
    .wrap {
      width: 100%;
    }

    h2 {
      font-size: 20px;
    }

    iframe {
      height: 750px;
    }
  }
</style>
